.promo {
    display: flex;
    position: relative;
    background-color: $color-ghost-white;
    border-radius: 32px;
    overflow: hidden;
    zoom: 1.1;

    @media (max-width: 1199px) {
        zoom: unset;
    }

    &__content-wrapper {
        flex: 1;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        min-height: 400px;
        max-width: 100%;
        overflow: hidden;

        @include vp-1023 {
            flex-direction: column;
            padding: 0;
            min-height: fit-content;
        }

        @include vp-767 {
            // &::after {
            //     content: "";
            //     position: absolute;
            //     z-index: 2;
            //     width: 100%;
            //     height: 70px;
            //     bottom: 0;
            //     left: 0;
            //     background: linear-gradient(
            //             180deg,
            //             rgba($color-default-white, 0) 0%,
            //             $color-default-white 100%
            //     );
            // }
        }

        // @include vp-767 {
        //   flex-direction: column;
        //   padding: 0;
        // }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: auto;
            object-position: center;
        }

        @include vp-1023 {
           height: auto;
           max-height: 900px;
        }

        // @media(max-width:820px){
        //     height: auto;
        //     max-height: 658px;
        // }
    }

    &__content-text {
        padding: 56px 40px 56px 72px;
        z-index: 3;
        width: calc(100% - 650px);
        flex-shrink: 0;

        @include vp-1279 {
            padding: 60px 32px 60px 64px;
            min-width: 602px;
            width: calc(100% - 650px);
        }

        @include vp-1023 {
            padding: 56px 56px 32px;
            max-width: 100%;
            width: 100%;
            min-width: 100%;
        }

        @include vp-767 {
            padding: 40px 32px 32px;
        }
    }

    &__background-gradient-wrapper {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;
            border-radius: 32px;
        }

        @include vp-1023 {
            top: unset;
            bottom: 0;
        }
    }

    &__background-image-wrapper {
        display: block;
        margin: 0;
        align-self: flex-end;
        max-width: 650px;
        min-width: 650px;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include vp-1023 {
            display: flex;
            justify-content: center;
            z-index: 1;
            max-width: 100%;
            min-width: 100%;
            height: auto;
        }

        @include vp-767 {
            // padding: 90px 16px 0 40px;
            padding: 0;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-width: 1400px;
        z-index: -1;
        @include vp-1023 {
            min-width: 100%;
        }
    }
    &__picture-bg {
        height: 100%;
        width: 100%;
        display: block;
        img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
        }
    }

    .title--h1 {
        font-size: 64px;
        line-height: 80px;
        margin-bottom: 16px;

        @include vp-1023 {
            color: $color-jaguar;
            font-size: 40px;
            line-height: 46px;
        }

        @include vp-767 {
            font-size: 36px;
            line-height: 44px;
        }
    }

    &__subtitle {
        margin: 0;
        margin-bottom: 12px;
        max-width: 550px;
        font-size: 24px;
        line-height: 40px;
        color: $color-black-russian;

        // @include vp-1023 {
        //   font-size: 16px;
        //   line-height: 2px;
        //   margin-bottom: 16px;
        //   color: $color-jaguar;
        // }

        @include vp-1023 {
            max-width: unset;
            font-size: 18px;
            line-height: 26px;
        }

        em {
            font-style: italic;
            font-weight: 600;

            @include vp-1023 {
                // margin-bottom: 0;

                font-family: $ff-sf-pro-display;
                // font-size: 18px;
                // line-height: 26px;

                color: $color-jaguar;
            }

            @include vp-767 {
                font-style: normal;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__search {
        margin-bottom: 40px;
    }

    &__text {
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        max-width: 100%;

        @include vp-1023 {
            max-width: unset;
            font-size: 18px;
            line-height: 26px;
        }

        p {
            margin-top: 0;
        }

        &:last-child {
            p {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &__text--bold {
        display: block;
        margin-bottom: 32px;
        font-style: italic;
        font-weight: 600;

        @include vp-1023 {

            font-family: $ff-sf-pro-display;
            // font-size: 18px;
            // line-height: 26px;

            color: $color-jaguar;
        }

        @include vp-767 {
            display: inline-block;
            font-style: normal;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
        }
    }

    &__order-buttons {
        display: flex;
    }

    &__order-button {
        margin-right: 16px;
        box-shadow: 0px 13px 28px rgba(67, 75, 238, 0.5);
        border-radius: 12px;
        padding: 28px 50px;

        font-weight: 700;
        font-size: 20px;
        min-width: 300px;
        line-height: 24px;
        letter-spacing: 0.03em;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        user-select: none;

        @include vp-1439 {
            padding: 28px 20px;
            min-width: unset;
            flex: 1;
        }

        &--green {
            background-color: $color-shamrock;
            box-shadow: 0px 13px 28px rgba(34, 214, 149, 0.5);
            margin-right: 0 !important;
        }

        @include vp-1023 {
            padding: 12px 20px;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.06em;
        }

        @include vp-767 {
            padding: 20px 5px;
            // box-shadow: none;
            margin-right: 12px;
            min-width: unset;
            width: auto;
            flex: 1;
        }
    }
}

.promo__wrapper {
    padding: 80px;
    align-self: center;
}

.promo__title {
    margin-bottom: 28px;
}

.promo__column {
    flex: 1;
    z-index: 1;
}

// .promo--m0 {
//   margin: 0;

//   p {
//     margin: 0;
//     max-width: unset;
//   }
// }

.promo__background-gradient-wrapper.mobile-only {
    img {
        max-width: unset;
        width: 100%;
        height: auto;
        transform: translate(0, 136px);
        display: block;
    }

    @include vp-767 {
        display: flex;
    }
}

.promo__radial-bg {
    position: absolute;
    top: 0;
    right: -170px;
    bottom: 0;
    left: 23%;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.promo-bonus {

    @include vp-767 {
        width: calc(100% + 32px);
        margin-left: -16px;
    }

    .title--h1 {
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 24px;

        @include vp-1023 {
            color: $color-jaguar;
            font-size: 42px;
            line-height: 48px;
        }

        @include vp-767 {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .promo__content-wrapper {
        min-height: 600px;
        &::after {
            display: none;
        }

        @include vp-1023 {
            min-height: fit-content;
        }
    }
    .promo__text {
        margin-bottom: 40px;
        color: $color-black-russian;
        font-size: 18px;
        line-height: 26px;
        .ql-size-large{
            font-size: 18px;
            line-height: 26px;
        }

        @include vp-1023 {
            margin-bottom: 24px;
        }

        @include vp-767 {
            font-size: 16px;
            line-height: 22px;
            .ql-size-large{
                font-size: 16px;
                line-height: 22px;
            }
        }

    }
    .promo__order-buttons {
        position: initial;
        align-items: center;
        @include vp-767 {
            flex-direction: column;
        }
        .btn {
            box-shadow: 0px 13px 28px 0px #22D69580;
            text-transform: uppercase;
            width: fit-content;
            padding: 20px 64px;
            margin: 0 64px 0 0;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            min-width: 236px;
            @include vp-767 {
                width: 100%;
                margin: 0 0 32px;
                &:last-child {
                    margin: 0;
                }
            }
            .icon {
                margin-right: 10px;
            }
        }
    }
}

.promo-partner {

    @include vp-767 {
        width: calc(100% + 32px);
        margin-left: -16px;
    }

    .title--h1 {
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 24px;

        @include vp-1023 {
            color: $color-jaguar;
            font-size: 42px;
            line-height: 48px;
        }

        @include vp-767 {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .promo__content-wrapper {
        min-height: 600px;
        flex-direction: row-reverse;
        &::after {
            display: none;
        }

        @include vp-1023 {
            flex-direction: column;
            min-height: fit-content;
        }
    }
    .promo__text {
        margin-bottom: 40px;
        color: $color-black-russian;
        font-size: 18px;
        line-height: 26px;

        &:last-child {
            margin-bottom: 0;
        }

        .ql-size-large{
            font-size: 18px;
            line-height: 26px;
        }

        @include vp-1023 {
            margin-bottom: 24px;
        }

        @include vp-767 {
            font-size: 16px;
            line-height: 22px;

            .ql-size-large{
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.promo-services {
    overflow: hidden;

    .title--h1 {
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 46px;

        @include vp-767 {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .promo__content-text {
        padding-bottom: 30px;
        min-width: 602px;

        @include vp-1279 {
            min-width: 550px;
            padding-bottom: 30px;
            padding-left: 30px;
            padding-top: 30px;
        }

        @include vp-1023 {
            min-width: 100%;
            padding-top: 40px;
        }
    }

    .promo__content-wrapper {
        flex-direction: row-reverse;
        @include vp-1023 {
            flex-direction: column;
        }
    }

    .promo__content-wrapper::after {
        @include vp-1023 {
            display: none;
        }
    }

    .promo__text {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 26px;

        @include vp-1023 {
            margin-bottom: 24px;
        }

        @include vp-767 {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .promo__order-buttons {
        position: initial;
        align-items: center;

        @include vp-767 {
            flex-direction: column;
        }

        .btn {
            box-shadow: 0px 13px 28px 0px #22D69580;
            text-transform: uppercase;
            width: fit-content;
            // padding: 23px 64px;
            margin: 0 64px 0 0;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.48px;
            padding: 16px 64px;
            border-radius: 10px;
            min-width: 236px;

            @include vp-767 {
                width: 100%;
                margin: 0 0 0px;
            }
        }
    }

    .promo__background-image-wrapper {
        display: block;
        margin: 0;
        align-self: flex-end;
        max-width: 600px;
        min-width: 600px;
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // @media (max-width: 1570px) {
        //   min-width: calc(100% - 602px);
        // }

        @include vp-1023 {
            display: flex;
            justify-content: center;
            z-index: 1;
            max-width: 100%;
            min-width: 100%;
            height: auto;
        }

        @include vp-767 {
            // padding: 90px 16px 0 40px;
            padding: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .promo__picture {
        display: block;
        width: 100%;
        // height: auto;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // width: 600px;
        img {
            width: 100%;
            height: auto;
            object-position: center;
        }
        // @include vp-1023 {
        //     position: initial;
        //     width: 100%;
        // }
      }
}
